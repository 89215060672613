<template>
  <div>アンケート一覧</div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
</style>
